<template>
<!-- 网络催办工单 -->
  <div style="padding:16px" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card>
      <el-form
        ref="queryForm"
        :model="queryParams"
        size="small"
        label-width="90px"
        @submit.native.prevent
      >
        <div class="formModel">
          <el-form-item label="工单号" prop="CTicketId">
            <el-input
              class="formItem"
              v-model="queryParams.CTicketId"
              placeholder="请输入工单号"
              clearable
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
            <el-form-item label="被催工单号" prop="TicketNo">
            <el-input
              class="formItem"
              v-model="queryParams.TicketNo"
              placeholder="请输入被催工单号"
              clearable
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="一级机房" prop="bizAreaName">
            <el-cascader
              v-model="queryParams.bizAreaName"
              :options="depOptions"
              :props="{
                multiple: true,
                expandTrigger: 'hover',
                label: 'dept_name',
                value: 'dept_name',
                children: 'children',
              }"
              collapse-tags
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="催办状态" prop="ticket_status">
            <el-select
              v-model="queryParams.ticket_status"
              placeholder="请选择催办状态"
              clearable
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="item in orderStatusOptions"
                :key="item.id"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="超时状态" prop="IsDelay">
            <el-select
              v-model="queryParams.IsDelay"
              placeholder="请选择超时状态"
              clearable
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="item in timeoutStatusOptions"
                :key="item.id"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="催办时间" prop="createdTimeRange">
            <el-date-picker
              v-model="createdTimeRange"
              type="daterange"
              class="formItem"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              clearable
              @keyup.enter.native="handleQuery"
            >
            </el-date-picker>
          </el-form-item>
          <div class="formItem btns">
            <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery"
              >搜索
            </el-button>
            <el-button icon="el-icon-refresh" size="small" @click="handleFormRest('queryForm')"
              >重置
            </el-button>
          </div>
        </div>
      </el-form>
    </el-card>
    <el-card style="margin-top: 15px; padding-bottom: 20px">
      <el-table v-loading="loading" :data="list" style="width: 100%; margin-top: 20px">
        <el-table-column property="CTicketId" width="160" label="工单号">
          <template slot-scope="scope">
            <a class="ticketId" :href="getTicketUrl(scope.row.CTicketId)" target="_blank">{{ scope.row.CTicketId }}</a>
          </template>
        </el-table-column>
        <el-table-column property="BizAreaName" width="100" label="一级机房"></el-table-column>
        <el-table-column property="TicketNo" label="腾讯工单号">
          <template slot-scope="scope">
            <span class="ticketId" @click="getDetail(scope.row)">{{ scope.row.TicketNo }}</span>
          </template>
        </el-table-column>
        <el-table-column property="RushBecause" width="160" label="催办原因"></el-table-column>
        <el-table-column property="TicketType" label="催办类型"></el-table-column>
        <el-table-column property="Creator" label="催办人"></el-table-column>
        <el-table-column property="Handler" label="当前处理人"></el-table-column>
        <el-table-column property="IsDelay" label="是否超时"></el-table-column>
        <el-table-column property="CEntryTime" label="催办时间"></el-table-column>
        <el-table-column property="ticket_status" label="催办状态"></el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageParams.page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[25, 50, 100]"
        :page-size.sync="pageParams.limit"
        :total="total"
        style="margin-top: 20px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { saveAs } from 'file-saver';
import { networkRushTickets,deptTree } from '@/api/monitor';
import {removeEmptyStringFields} from '@/utils/common'
export default {
  name: 'workOrderList',
  data() {
    return {
      fullscreenLoading: false,
      loading: false,
      total: 0,
      list: [],
      queryParams : {
        TicketNo: '', //工单号
        CTicketId: '', //工单名称
        bizAreaName:[],
        ticket_status: '', //工单状态
        IsDelay: '', //超时状态
        startCreateTime: '', //结单开始时间
        endCreateTime: '', //结单结束时间
      },
      pageParams: {
        page: 1,
        limit: 25,
      },
      orderStatusOptions: [
        //工单状态数据
        {
          id: 1,
          value: '未完成',
        },
        {
          id: 2,
          value: '已完成',
        },
      ],
      createdTimeRange:[],
      timeoutStatusOptions: [
        //超时状态
        {
          id: 1,
          value: '是',
        },
        {
          id: 2,
          value: '否',
        },
      ],

      depOptions: [], //一级机房数据
    };
  },
  created() {
    if (sessionStorage.getItem('network_rush_ticekts_params')) {
      this.queryParams = JSON.parse(sessionStorage.getItem('network_rush_ticekts_params'));
      if(this.queryParams.startCreateTime){
        this.createdTimeRange = [this.queryParams.startCreateTime,this.queryParams.endCreateTime]
      }
    }
    this.deptTree();
    this.getList();
  },
  methods: {
    //一级机房数据
    deptTree() {
      deptTree().then((res) => {
        this.depOptions = res?.data?.data;
      });
    },
    //查看详情
    getDetail(row) {
      this.$router.push({
        path: '/appManage/monitor/networkRushTicket/detail',
        query: {
          CTicketId: row.CTicketId,
        },
      });
    },
    //列表
    getList() {
      this.loading = true;
      networkRushTickets({ ...this.pageParams,...this.formattingParams()})
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.data;
            this.total = res.data.count;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formattingParams(){
      let params = JSON.stringify(this.queryParams);
      let paramsNew = JSON.parse(params);
      paramsNew.bizAreaName = this.queryParams.bizAreaName.map((row) => row.at(-1));
      if (this.createdTimeRange?.length) {
        paramsNew.startCreateTime = `${this.createdTimeRange[0]} 00:00:00`; //创建开始时间
        paramsNew.endCreateTime = `${this.createdTimeRange[1]} 23:59:59`; //创建截止时间
      }
      paramsNew.bizAreaName = paramsNew.bizAreaName.join(';');
      return removeEmptyStringFields(paramsNew)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.pageParams.page = 1;
      this.pageParams.limit = 25;
      this.getList();
    },
    // 重置
    handleFormRest(formName) {
      sessionStorage.removeItem('network_rush_ticekts_params');
      this.queryParams = {
        TicketNo: '', //工单号
        CTicketId: '', //工单名称
        bizAreaName:[],
        ticket_status: '', //工单状态
        IsDelay: '', //超时状态
        startCreateTime: '', //结单开始时间
        endCreateTime: '', //结单结束时间
      };
      this.createdTimeRange = [];
      this.handleQuery();
    },
    // 监听每页条数改变
    handleSizeChange(newSize) {
      this.pageParams.limit = newSize;
      this.pageParams.page = 1;
      this.getList();
    },
    getTicketUrl(ticketId){
      return `${process.env.VUE_APP_TICKET_URL}appManage/tickets/details?params=${ticketId}`;
    },
    // 监听当前页面变化
    handleCurrentChange(newPage) {
      this.pageParams.page = newPage;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .demo-ruleForm .el-form-item {
  margin-bottom: 16px !important;
}
.item-shortcut-statement {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  span {
    padding-right: 20px;
    font-size: 14px;
    line-height: 18px;
    color: #333;
  }
}
.ticketId {
  color: #1890ff;
  cursor: pointer;
}
.standard-blue-link {
  color: #1890ff;
  text-decoration: none;
}
.formModel {
  display: flex;
  flex-flow: row wrap;
}
.formItem {
  // width: 175px !important;
  margin-bottom: 20px;
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-card__body {
  padding: 15px 20px 0 20px !important;
}
.btns {
  margin-left: 20px;
}
</style>
