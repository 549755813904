var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticStyle: { padding: "16px" }
    },
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              attrs: {
                model: _vm.queryParams,
                size: "small",
                "label-width": "90px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "formModel" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工单号", prop: "CTicketId" } },
                    [
                      _c("el-input", {
                        staticClass: "formItem",
                        attrs: { placeholder: "请输入工单号", clearable: "" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.CTicketId,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "CTicketId", $$v)
                          },
                          expression: "queryParams.CTicketId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "被催工单号", prop: "TicketNo" } },
                    [
                      _c("el-input", {
                        staticClass: "formItem",
                        attrs: {
                          placeholder: "请输入被催工单号",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.TicketNo,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "TicketNo", $$v)
                          },
                          expression: "queryParams.TicketNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "一级机房", prop: "bizAreaName" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.depOptions,
                          props: {
                            multiple: true,
                            expandTrigger: "hover",
                            label: "dept_name",
                            value: "dept_name",
                            children: "children"
                          },
                          "collapse-tags": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.queryParams.bizAreaName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "bizAreaName", $$v)
                          },
                          expression: "queryParams.bizAreaName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "催办状态", prop: "ticket_status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择催办状态",
                            clearable: ""
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.ticket_status,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "ticket_status", $$v)
                            },
                            expression: "queryParams.ticket_status"
                          }
                        },
                        _vm._l(_vm.orderStatusOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.value, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "超时状态", prop: "IsDelay" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择超时状态",
                            clearable: ""
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.IsDelay,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "IsDelay", $$v)
                            },
                            expression: "queryParams.IsDelay"
                          }
                        },
                        _vm._l(_vm.timeoutStatusOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.value, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "催办时间", prop: "createdTimeRange" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "formItem",
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.createdTimeRange,
                          callback: function($$v) {
                            _vm.createdTimeRange = $$v
                          },
                          expression: "createdTimeRange"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "formItem btns" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-search"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v("搜索 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleFormRest("queryForm")
                            }
                          }
                        },
                        [_vm._v("重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "15px", "padding-bottom": "20px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: { data: _vm.list }
            },
            [
              _c("el-table-column", {
                attrs: { property: "CTicketId", width: "160", label: "工单号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "ticketId",
                            attrs: {
                              href: _vm.getTicketUrl(scope.row.CTicketId),
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(scope.row.CTicketId))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  property: "BizAreaName",
                  width: "100",
                  label: "一级机房"
                }
              }),
              _c("el-table-column", {
                attrs: { property: "TicketNo", label: "腾讯工单号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "ticketId",
                            on: {
                              click: function($event) {
                                return _vm.getDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(scope.row.TicketNo))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  property: "RushBecause",
                  width: "160",
                  label: "催办原因"
                }
              }),
              _c("el-table-column", {
                attrs: { property: "TicketType", label: "催办类型" }
              }),
              _c("el-table-column", {
                attrs: { property: "Creator", label: "催办人" }
              }),
              _c("el-table-column", {
                attrs: { property: "Handler", label: "当前处理人" }
              }),
              _c("el-table-column", {
                attrs: { property: "IsDelay", label: "是否超时" }
              }),
              _c("el-table-column", {
                attrs: { property: "CEntryTime", label: "催办时间" }
              }),
              _c("el-table-column", {
                attrs: { property: "ticket_status", label: "催办状态" }
              })
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              "current-page": _vm.pageParams.page,
              layout: "total, sizes, prev, pager, next, jumper",
              "page-sizes": [25, 50, 100],
              "page-size": _vm.pageParams.limit,
              total: _vm.total
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.pageParams, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.pageParams, "page", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.pageParams, "limit", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.pageParams, "limit", $event)
              },
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }